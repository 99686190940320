<template>
	<div>
		<van-nav-bar
			title="AI健康套餐"
			fixed
			:z-index='111'
			class="qjc-nav-bar"
		/>
		<div class="goods">
			<div class="combos qjc-margin-ct qjc-bg-fff">
				<ul class="combos-name qjc-c-dark qjc-fts-32 qjc-clearfix">
					<li v-for="(item, index) in combos.list" @click="checkCombo(index)" :class="{active: item.name == combos.active.name}" class="qjc-fl">
						<div>{{ item.name }}</div>
					</li>
				</ul>
				<div class="combos-detail">
					<van-collapse v-model="collapse">
						<van-collapse-item 
							:is-link="false"
							name="0"
							:border='false'
						>
							<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
								<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
									<span class="qjc-ftw-b">總體健康評估和生命預測</span>
									<van-icon
										name="arrow-down"
										:class="{ on: /0/g.test(collapse) }"
									/>
								</div>
								<div 
									v-html="combos.active.overall" 
									class="right qjc-texta-c qjc-fr"
								></div>
							</div>
							<div slot="default" class="describe qjc-texta-l">
								<p>根據您的各項指標，評估您屬於健康人群、亞健康人群還是醫療需求人群；</p>
							</div>
						</van-collapse-item>
						<van-collapse-item 
							:is-link="false"
							name="1"
							:border='false'
						>
							<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
								<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
									<span class="qjc-ftw-b">體型評估</span>
									<van-icon
										name="arrow-down"
										:class="{ on: /1/g.test(collapse) }"
									/>
								</div>
								<div 
									v-html="combos.active.body" 
									class="right qjc-texta-c qjc-fr"
								></div>
							</div>
							<div slot="default" class="describe qjc-texta-l">
								<p>根據您的體型指標，評估您的整體體型</p>
								<p>並針對體質指數BMI、腰臀比WHR和體脂率BFR進行專項大數據評估。</p>
							</div>
						</van-collapse-item>
						<van-collapse-item
							:is-link="false"
							name="2"
							:border='false'
						>
							<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
								<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
									<span class="qjc-ftw-b">醫療費用預測</span>
									<van-icon
										name="arrow-down"
										:class="{ on: /2/g.test(collapse) }"
									/>
								</div>
								<div 
									v-html="combos.active.medical" 
									class="right qjc-texta-c qjc-fr"
								></div>
							</div>
							<div slot="default" class="describe qjc-texta-l">
								<p>根據您的健康狀況和所在居住地的醫療費用水平，預測您未來可能會承擔的醫療費用。</p>
							</div>
						</van-collapse-item>
						<van-collapse-item
							:is-link="false"
							name="3"
							:border='false'
						>
							<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
								<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
									<span class="qjc-ftw-b">未來疾病風險預測</span>
									<van-icon
										name="arrow-down"
										:class="{ on: /3/g.test(collapse) }"
									/>
								</div>
								<div 
									v-html="combos.active.illness" 
									class="right qjc-texta-c qjc-fr"
								></div>
							</div>
							<div slot="default" class="describe qjc-texta-l">
								<p>根據您的健康狀況，通過大數據評估，給出不同的疾病風險預測</p>
								<p>健康人群：系統疾病風險（無同族群對比）</p>
								<p>亞健康人群：慢性病風險</p>
								<p>醫療需求人群：急重症風險</p>
							</div>
						</van-collapse-item>
						<!-- <van-collapse-item
							:is-link="false"
							name="4"
							:border='false'
						>
							<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
								<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
									<span class="qjc-ftw-b">不患病增壽</span>
									<van-icon
										name="arrow-down"
										:class="{ on: /4/g.test(collapse) }"
									/>
								</div>
								<div 
									v-html="combos.active.noSick" 
									class="right qjc-texta-c qjc-fr"
								></div>
							</div>
							<div slot="default" class="describe qjc-texta-l">
								<p>若不患上某種疾病，相應可增壽的年數。</p>
								<p>(醫療需求人群不適用)</p>
							</div>
						</van-collapse-item> -->
						<!-- <van-collapse-item
							:is-link="false"
							name="5"
							:border='false'
						>
							<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
								<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
									<span class="qjc-ftw-b">生化指標評估</span>
									<van-icon
										name="arrow-down"
										:class="{ on: /5/g.test(collapse) }"
									/>
								</div>
								<div 
									v-html="combos.active.biom" 
									class="right qjc-texta-c qjc-fr"
								></div>
							</div>
							<div slot="default" class="describe qjc-texta-l">
								<p>對您的各項生化指標進行大數據評估；</p>
								<p>根據生化指標進行更準確的疾病風險和健康風險預測。</p>
							</div>
						</van-collapse-item> -->
						<van-collapse-item
							:is-link="false"
							name="6"
							:border='false'
						>
							<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
								<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
									<span class="qjc-ftw-b">同族群對比</span>
									<van-icon
										name="arrow-down"
										:class="{ on: /6/g.test(collapse) }"
									/>
								</div>
								<div 
									v-html="combos.active.group" 
									class="right qjc-texta-c qjc-fr"
								></div>
							</div>
							<div slot="default" class="describe qjc-texta-l">
								<p>將您的各項評估結果與同年齡、同性別的人群大數據進行對比。</p>
								<p>(健康人群部分結果不適用)</p>
							</div>
						</van-collapse-item>
						<!-- <van-collapse-item
							:is-link="false"
							name="7"
							:border='false'
						>
							<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
								<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
									<span class="qjc-ftw-b">未來健康風險預測</span>
									<van-icon
										name="arrow-down"
										:class="{ on: /7/g.test(collapse) }"
									/>
								</div>
								<div 
									v-html="combos.active.health" 
									class="right qjc-texta-c qjc-fr"
								></div>
							</div>
							<div slot="default" class="describe qjc-texta-l">
								<p>根據您的健康狀況，通過大數據評估，給出進一步的健康風險預測</p>
								<p>健康人群：生理年齡；</p>
								<p>亞健康人群：四高風險；</p>
								<p>醫療需求人群：癌症風險（若有）</p>
							</div>
						</van-collapse-item> -->
						<van-collapse-item
							:is-link="false"
							name="8"
							:border='false'
						>
							<div class="combos-detail-tit qjc-c-dark qjc-clearfix" slot="title">
								<div class="left qjc-fts-28 qjc-fl qjc-texta-l">
									<span class="qjc-ftw-b">健康管理和改善方案</span>
									<van-icon
										name="arrow-down"
										:class="{ on: /8/g.test(collapse) }"
									/>
								</div>
								<div 
									v-html="combos.active.improve" 
									class="right qjc-texta-c qjc-fr"
								></div>
							</div>
							<div slot="default" class="describe qjc-texta-l">
								<p>根據您的各項數據和評估結果，通過大數據AI分析，給出為您量身打造的、循序漸進的改善目標及詳細具體的改善方案。</p>
							</div>
						</van-collapse-item>
					</van-collapse>
					<div class="price">
						<sup>$</sup>
						<!-- allPrice -->
						<!-- <b>{{ combos.active.price }}</b> -->
						<b>{{ allPrice }}</b>
						<sub>港元</sub>
					</div>
					<!-- <div class="org-price qjc-relative">
						原價：${{ combos.active.orgPrice }}港元
					</div> -->
				</div>
			</div>
			
			<div class='foot_cell'>
				<van-button class="history_btn" @click="handleToHistory">歷史記錄</van-button>
				<van-button class="pay_btn" @click="handleToPay">立即獲取</van-button>
			</div>
		</div>
		
		
		<!-- 是否有检查结果或预约体检弹窗 -->
		<van-popup class="is-book" v-model="way.show" :close-on-click-overlay="false">
			<div>
			    <div class="markBoxError" >
			        <div class="markBoxErrorImg">
			            <img src="../../assets/images/tips.png" alt="">
			        </div>
			        <div class="tipText2 tipText3">
						<div class="err_tip">
							<div class="err_tip_title">小主，請先量好<span>體重、腰圍、臀圍</span>等信息。</div>
							<div class="err_tip_text">特別注意：體重的計量單位是公斤(kg)，腰臀圍的計量單位是釐米(cm)，再點擊“確定”。</div>
						</div>
			        </div>
			        <div class="btnGroup">
			            <van-button class="btnEnt2 qjc-fts-32" @click="toQuestion">確定</van-button>
			        </div>
			    </div>
			</div>
			<div @click="way.show = false" class="close qjc-absolute qjc-round">
				<van-image
					width="0.265rem"
					height="0.265rem"
					:src="require('@/assets/images/close.png')"
				/>
			</div>
		</van-popup>
		
		<!-- 评估未完成弹窗 -->
		<van-popup 
			v-model="complete" 
			:close-on-click-overlay="false"
			class="assess-nodone"
			:class="{ 'yjqz-alert': yjqz }"
		>
			<div v-if="yjqz" class="yjqz-box qjc-absolute">
				<van-image
					width="100%"
					height="100%"
					:src="require('@/assets/images/index_yjqz.png')"
				/>
				<van-button
					to="/question"
					round
					class="qjc-bg-ffff qjc-ftw-b qjc-fts-32 qjc-c-primary"
				>
					前往完成
				</van-button>
			</div>
			<div v-else>
				<van-image
					width="3.2rem"
					height="2.55rem"
					:src="require('@/assets/images/errorTip.png')"
				/>
				<p class="qjc-fts-32 qjc-c-dark">您上一次的健康大數據評估尚未完成，請先完成該次評估。</p>
				<!-- <van-button
					@click="$router.push('/')"
					round
					class="qjc-c-primary qjc-ftw-b qjc-borderc-primary qjc-fts-32"
				>
					返回首頁
				</van-button> -->
				<van-button
					to="/newgoods/question"
					round
					class="qjc-bg-primary qjc-ftw-b qjc-fts-32 qjc-c-fff"
				>
					繼續完成
				</van-button>
			</div>
			<div @click="complete = false" class="close qjc-absolute qjc-round">
				<van-image
					width="0.265rem"
					height="0.265rem"
					:src="require('@/assets/images/close.png')"
				/>
			</div>
		</van-popup>
		
		<!-- 報告未生成 -->
		<van-popup
			v-model="hasReport"
			:close-on-click-overlay="false"
			class="has-report"
		>
			<van-image
				width="2.97rem"
				height="2.19rem"
				:src="require('@/assets/images/hasReport.png')"
			/>
			<div class="qjc-texta-l">
				您尚有未生成報告的已購買套餐，無法重復購買。<br>
				在您完成線下體檢後，我們會自動獲取您的生化指標結果並生成報告，請您耐心等待。
			</div>
			
			<van-button @click="hasReport = false" class="qjc-c-fff qjc-block qjc-ftw-b qjc-bg-primary qjc-fts-32" round>確認</van-button>
			
			<div @click="hasReport = false" class="close qjc-absolute qjc-round">
				<van-image
					width="0.265rem"
					height="0.265rem"
					:src="require('@/assets/images/close.png')"
				/>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Icon, List, Image, Toast, Button, Popup, Collapse, CollapseItem, } from 'vant'
	Vue.use(NavBar)
	   .use(Icon)
	   .use(List)
	   .use(Image)
	   .use(Button)
	   .use(Popup)
	   .use(Collapse)
	   .use(CollapseItem)
	   .use(Toast);
	   
	import { pagingData, isPay, packagePrice } from '@/js/axios.js'
	   
	export default{
		name: 'goodsMirror',
		data (){
			return {
				complete: false,
				yjqz: false,
				hasReport: false,
				way: {
					show: false,
					result: []
				},
				allPrice: '',
				collapse: [],// 折叠面板展开数组
				combos: {
					active: {},// 当前显示套餐 默认为套餐列表第一个(created钩子里赋值)
					list: [
						{
							package: '',
							name: '--',//套餐名称
							overall: "<i class='iconfont iconduihao'></i>",//總體健康評估和生命預測
							body: "<i class='iconfont iconduihao'></i>",//體型評估
							medical: "未来1年",//醫療費用預測
							illness: "疾病種類：最多5種<br>風險預測：未來5年",//未來疾病風險預測
							noSick: "<i class='iconfont iconchahao'></i>",//不患病增壽
							biom: "<i class='iconfont iconchahao'></i>",//生化指標評估
							group: "<i class='iconfont iconduihao'></i>",//同族群對比
							health: "<i class='iconfont iconchahao'></i>",//未來健康風險預測
							improve: "體型改善",//健康管理和改善方案
							price: '--',//价格
							orgPrice: '--',//原價
							blood_price: '--'//原價
						},//体验套餐
					]//套餐列表
				},// 套餐
				
				timer: null,
				istrue: true,
				txt: '確定'
			}
		},
		created: function(){
			// 获取套餐价格信息
			this.getPackageInfo(() => {
				// 显示所选套餐
				this.combos.active = this.combos.list[0];
				this.allPrice = this.combos.active.price
			});
		},
		computed: {
			
		},
		mounted() {
			
			
			document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		methods: {
			addHandle(){
			  //定义n=5秒
			  let n=5
			  //定义定时器time
			  let time=setInterval(()=>{
				//禁用
				this.istrue = true
				//改变倒计时文字提示
				this.txt='確定('+n+'s)'
				n--
				//如果n<0，清除定时器，禁用状态取消，文字提示为空（不显示）
				if(n<0){
				  this.txt="確定"
				  this.istrue=false
				  clearInterval(time)
				}
				
			  },1000)
			},
			getPackageInfo (callback){//获取套餐信息
				packagePrice().then(res => {
					if(res.status == 1){
						var result = res.data;
						
						// 疫境求真不显示
						result = result.filter(item => (item.id == '6' || item.id == '7' || item.id == '8'));
						
						//套餐详情data id是反过来的，即3-2-1，如新增套餐(id自增),直接从data前面unshift
						result.reverse();
						for(var i=0;i<result.length;i++){
							if(result[i].id == 6) {
								this.combos.list[i].package = result[i].id;
								// this.combos.list[i].name = result[i].name.substr(0, 4);
								this.combos.list[i].name = result[i].name;
								this.combos.list[i].price = result[i].price;
								this.combos.list[i].orgPrice = result[i].cost_price;
								this.combos.list[i].blood_price = result[i].blood_price;
							}
						}
						
						callback?callback():'';
					}else{
						this.$router.push('/');
					}
				});
			},
			handleToHistory() {
				this.$router.push('/user/history')
			},
			handleToPay() {
				if(this.$store.state.isLogin){
					isPay().then(res => {
						// console.log(res);
						if(res.code == 200){
							if(res.data.tips == 0){
								this.complete = true;
								
								if(res.data.package == 4){
									this.yjqz = true;
								}
							}else{
								this.hasReport = true;
							}
						}else{
							this.addHandle()
							this.way.show = true;
						}
					})
				}else{
					this.$router.push({
						path: '/login',
						query: {
							toUrl: '/goods/mirror'
						},
					});
				}
			},
			toQuestion() {
				if(sessionStorage.getItem('package') == null){
					sessionStorage.setItem('package', 6);
				}
				
				this.$router.push({path: "/newgoods/question"})
				
				// this.$router.push({path: "/payment/0", }).catch( err => {
					
				// });
			},
		}
	}
</script>
<style scoped>
	.van-collapse-item >>> .van-cell{
		padding: 0!important;
	}
	.pro-code >>> input{
		width: 3.18rem;
		font-size: 0.28rem;
		color: #D0D7F5;
		background-color: #5873E9;
		border-radius: 0.37rem;
	}
	.pro-code >>> input::-webkit-input-placeholder{
		font-size: 0.28rem;
	    color: #D0D7F5;
	}
	.pro-code >>> input::-moz-placeholder{
		font-size: 0.28rem;
	    color: #D0D7F5;
	}
	.pro-code >>> input:-ms-input-placeholder{
		font-size: 0.28rem;
	    color: #D0D7F5;
	}
	
	.right >>> .iconchahao{
		font-size: 0.2rem;
	}
	
	
	.markBox{
	    width:6.3rem;
	    height:6.37rem;
	    background:rgba(255,255,255,1);
	    border-radius:0.16rem;
	    overflow: hidden;
	}
	.markBox img{
	    display: block;
	    margin: 0.56rem auto;
	    width:3.2rem;
	    height:2.55rem;
	}
	.markBoxError{
	    /*margin: 3.28rem auto 0 auto;*/
	    padding-bottom: 0.2rem;
	    width: 100%;
	    background:rgba(255,255,255,1);
		border-radius: 0.16rem;
	}
	.markBoxErrorImg img{
		width: 2.1rem;
		height: 2.1rem;
		margin: 0 auto;
		margin-top: -0.8rem;
	}
	.tipTit{
	    width: 100%;
	    height: 0.8rem;
	    line-height: 0.8rem;
	    color: white;
	    text-indent: 0.4rem;
	    text-align: left;
	    font-size: 0.4rem;
	    background: #6883FB;
	}
	.mark .close{
	    margin: 0.5rem auto 0 !important;
	
	    padding: 0.28rem !important;
	    width:0.27rem !important;
	    height:0.27rem !important;
	    border:1px solid rgba(255,255,255,1) !important;
	    border-radius:50%;
	    background-image: url('../../assets/images/close.png');
	    background-repeat: no-repeat;
	    background-size: 50% 50%;
	    background-position: 50% 50%;
	}
	.tipText{
	    margin: 0 auto;
	    width:4.56rem;
	    height:0.79rem;
	    font-size:0.32rem;
	    font-family:PingFang SC;
	    font-weight:400;
	    color:rgba(64,68,77,1);
	    line-height:0.48rem;
	    text-align: center;
	}
	.tipText2{
	    margin: 0.48rem auto 0.56rem;
	    padding: 0 0.33rem;
	    font-size: 0.28rem;
	    font-family:PingFang SC;
	    font-weight:400;
	    color:rgba(119,127,143,1);
	    line-height: 0.42rem;
	    text-align: center;
	}
	.tipText3{
	    text-align: left!important;
	}
	.tipText2>p{
		text-align: left!important;
		line-height: 0.42rem;
		color: #A1A7B2;
	}
	.tipText2 p:first-child{
		margin-top: -0.12rem;
	}
	.tipText2 .err_tip {
		font-size: 0.28rem;
		color: #40444D;
		line-height: 0.52rem;
		/* padding: 0 0.2rem;
		text-align: center; */
	}
	.err_tip_title {
		color: #222222;
		margin-bottom: 0.2rem;
	}
	.err_tip span {
		color: #6681FA;
	}
	.btnEnt2 {
		width: 5.2rem;
		height: 0.88rem;
		font-size: 0.32rem;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #6681FA;
		margin: 0 auto;
		border-radius: 0.08rem;
	}
	
</style>
<style lang="scss" scoped>
	@import '../../assets/iconfont/iconfont.css';
	.van-nav-bar{
		background-color: #F9FAFC;
	}
	.goods {
		width: 100%;
		min-height: 100vh;
		padding-top: 0.88rem;
		padding-bottom: 0.3rem;
		background-color: #7D94FA;
	}
	.is-book{
		width: 88%;
		margin-top: -0.6rem;
		padding: 0.4rem 0.3rem;
		border-radius: 0.08rem;
		overflow-y: visible;
		.way{
			max-height: 100%;
			overflow-y: auto;
		}
		
		ul{
			color: #777F8F;
			line-height: 0.46rem;
			
			li{
				position: relative;
				font-weight: 400;
				padding-left: 0.2rem;
				
				&::before{
					content: '';
					position: absolute;
					left: 0;
					top: 0.5em;
					width: 0.08rem;
					height: 0.08rem;
					background-color: #777F8F;
					border-radius: 50%;
				}
				.van-icon{
					display: inline-block;
					width: 0.1rem;
					vertical-align: middle;
					font-size: 0.2rem;
					line-height: 0.46rem;
				}
			}
		}
		.van-checkbox-group{
			margin-top: 0.32rem;
			
			.van-checkbox{
				line-height: 0.48rem;
				align-items: baseline;
				margin-bottom: 0.1rem;
			}
			.bk-price::before{
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 0.02rem;
				min-height: 1px;
				margin-top: -0.01rem;
				background-color: #777f8f;
			}
		}
		.van-button{
			border-radius: 0.08rem;
			margin-top: 0.32rem;
		}
		.buy-tips{
			color: #777F8F;
			margin-top: 0.12rem;
		}
		.close{
			width: 0.72rem;
			height: 0.72rem;
			padding-top: 0.22rem;
			left: 50%;
			bottom: -1.22rem;
			margin-left: -0.36rem;
			border: 0.02rem solid #fff;
		}
	}
	.assess-nodone{
		width: 6.3rem;
		padding: 0.56rem;
		border-radius: 0.16rem;
		overflow-y: visible;
		
		p{
			line-height: 0.52rem;
			margin: 0.3rem 0;
		}
		.van-button{
			width: 2.5rem;
			line-height: 0.76rem;
			margin: 0 0.04rem;
		}
		.close{
			width: 0.72rem;
			height: 0.72rem;
			padding-top: 0.22rem;
			left: 50%;
			bottom: -1.72rem;
			margin-left: -0.36rem;
			border: 0.01rem solid #fff;
		}
		
		&.yjqz-alert{
			width: 6.5rem;
			height: 10.14rem;
			padding: 0;
			background-color: transparent;
			
			.yjqz-box{
				.van-button{
					position: absolute;
					left: 4%;
					bottom: 0.48rem;
					width: 91%;
					height: 0.74rem;
					border-radius: 0.08rem;
				}
			}
		}
	}
	.has-report{
		width: 5.78rem;
		padding: 0.4rem;
		border-radius: 0.08rem;
		overflow: visible;
		
		&>div.qjc-texta-l{
			color: #777F8F;
			font-size: 0.26rem;
			line-height: 0.4rem;
			font-weight: 400;
			margin: 0.32rem 0 0.4rem;
		}
		.van-button{
			width: 100%;
			height: 0.88rem;
			line-height: 0.88rem;
			border-radius: 0.08rem;
		}
		.close{
			width: 0.72rem;
			height: 0.72rem;
			padding-top: 0.21rem;
			left: 50%;
			bottom: -1.5rem;
			margin-left: -0.36rem;
			border: 0.01rem solid #fff;
		}
	}
	
	.combos{
		width: 7.02rem;
		margin-top: 0.24rem;
		border-radius: 0.16rem;
		
		.combos-name{
			border-bottom: 0.01rem solid #DCDEE5;
			
			li{
				// width: 33.333333%;
				width: 100%;
				text-align: center;
				padding: 0.3rem 0;
				position: relative;
				
				div{
					padding: 0 0.3rem;
					height: 0.64rem;
					line-height: 0.32rem;
				}
				&:after{
					content: '';
					display: block;
					width: 0rem;
					position: absolute;
					left: 50%;
					bottom: 0;
					transform: translateX(-50%);
					border-bottom: 0.06rem solid transparent;
					transition: all 0.3s linear;
				}
				&:not(:last-child) div{
					border-right: 0.01rem solid #DCDEE5;
				}
				&.active div{
					font-weight: bold;
					color: #6681FA;
				}
				&.active:after{
					width: 1.25rem;
					border-bottom-color: #6681FA;
				}
			}
		}
		.combos-detail{
			padding: 0 0.32rem 0.4rem;
			margin-bottom: 0.8rem;
			
			.combos-detail-tit{
				display: flex;
				align-items: center;
				height: 1.23rem;
				line-height: 1.23rem;
				border-bottom: 0.01rem solid #DCDEE5;
				
				.van-icon{
					margin-left: 0.1rem;
					vertical-align: middle;
					transform: rotateZ(0deg);
					transition: all .3s;
				}
				.van-icon.on{
					transform: rotateZ(-180deg);
				}
				.left{
					width: 60%;
					
					span{
						font-weight: 500;
					}
				}
				.right{
					width: 40%;
					font-size: 0.24rem;
					line-height: 0.28rem;
					vertical-align: middle;
				}
			}
			.describe{
				width: 3.1rem;
				line-height: 0.36rem;
				font-size: 0.24rem;
			}
		}
		.price{
			padding-top: 0.48rem;
			
			sup,sub{
				font-size: 0.2rem;
				font-weight: bold;
			}
			sup{
				vertical-align: top;
			}
			sub{
				vertical-align: text-bottom;
			}
			b{
				font-size: 0.5rem;
				display: inline-block;
				height: 0.5rem;
				line-height: 0.5rem;
			}
		}
		.org-price{
			font-size: 0.24rem;
			font-weight: 400;
			display: inline-block;
			color: #777f8f;
			
			&::before{
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				height: 0.02rem;
				min-height: 1px;
				margin-top: -0.01rem;
				background-color: #777f8f;
			}
		}
	}
	.foot_cell {
		width: 100%;
		padding: 0 0.48rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.history_btn {
		width: 48%;
		height: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0.4rem;
		color: #9e431a;
		font-weight: bold;
		border: none;
		background: linear-gradient(180deg,rgba(250,229,149,1) 0%,rgba(241,168,60,1) 100%);
	}
	.pay_btn {
		width: 48%;
		height: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0.4rem;
		color: #9e431a;
		font-weight: bold;
		border: none;
		background: linear-gradient(180deg,rgba(250,229,149,1) 0%,rgba(241,168,60,1) 100%);
	}
	.grxx {
		font-size: 0.32rem;
		color: #6681FA;
	}
</style>